/* eslint-disable react/no-array-index-key */

'use client';

import { Stack, Text } from '@lualtek/react-components';
import jsonTokens from '@lualtek/tokens/web/tokens.json';
import { OTPInput, OTPInputProps, REGEXP_ONLY_DIGITS } from 'input-otp';
import { m } from 'motion/react';
import { FC } from 'react';
import { Except } from 'type-fest';

import styles from './otp.module.css';
import { OTPSlot } from './otp-slot';

export type OtpProps = Except<OTPInputProps, 'children' | 'render' | 'maxLength'> & {
  invalid?: boolean;
}

const invalidVariants = {
  shake: {
    x: [0, -10, 10, -8, 8, -6, 6, -4, 4, -2, 2, 0],
    transition: {
      duration: 0.5,
      easing: jsonTokens.easing.exit,
    },
  },
};

export const Otp: FC<OtpProps> = ({
  className,
  invalid,
  ...otherProps
}) => (
  <m.div
    animate={invalid ? 'shake' : 'none'}
    variants={invalidVariants}
  >
    <OTPInput
      {...otherProps}
      data-testid="otp-input"
      maxLength={6}
      containerClassName={styles.Input}
      pattern={REGEXP_ONLY_DIGITS}
      pasteTransformer={pasted => pasted.replaceAll('-', '')}
      textAlign="center"
      render={({ slots }) => (
        <Stack direction="row" vAlign="center" fill={false} columnGap={8}>
          <Stack direction="row">
            {slots.slice(0, 3).map((slotProps, idx) => (
              <OTPSlot key={idx} {...slotProps} />
            ))}
          </Stack>

          <Text dimmed={4} weight="bold">—</Text>

          <Stack direction="row">
            {slots.slice(3).map((slotProps, idx) => (
              <OTPSlot key={idx} {...slotProps} />
            ))}
          </Stack>
        </Stack>
      )}
    />
  </m.div>
);

Otp.displayName = 'Otp';
