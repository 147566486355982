
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Button, Spinner, Stack, Text, Textfield, Title, } from '@lualtek/react-components';
import { differenceInSeconds, isBefore, } from 'date-fns';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState, } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useLoginCall } from '@/api/use-login';
import { useUser } from '@/api/user';
import { Logo } from '@/components/common/shared/logo';
import { MetaData } from '@/components/common/shared/meta';
import { Otp } from '@/components/common/shared/otp';
import { useFeedbackContext } from '@/context/use-feedback-context';
import { useMobileContext } from '@/context/use-mobile-context';
import { useFormHelpers } from '@/core/helpers/form';
import { Trans, useI18nHelpers, useTranslate } from '@/core/i18n';
import { useCurrentQuery } from '@/core/navigation/use-current-query';
import { getPageServerSideProps } from '@/core/page-helpers/get-page-props';
type LoginFormData = {
    username: string;
    otp: string;
};
const LoginPage = () => {
    const router = useRouter();
    const { next, getFullQuery, } = useCurrentQuery();
    const { addFeedback } = useFeedbackContext();
    const { t } = useTranslate();
    const { tError } = useI18nHelpers();
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<string>('');
    const { isMobile } = useMobileContext();
    const { loading: loadingUser } = useUser();
    const { otpCreate, optVerify, loadingCreate, loadingVerify, errorVerify: error, setErrorVerify, expirationOtp, jwtOtp, setExpirationOtp, setJwtOtp, } = useLoginCall({
        onError: (error) => {
            addFeedback({
                title: t('login:toast.loginError.title'),
                children: tError(error?.key),
                kind: 'danger',
            });
        },
    });
    const { commonRegisterEmail } = useFormHelpers();
    const { register, handleSubmit, formState, control, setValue, } = useForm<LoginFormData>({
        defaultValues: {
            username: '',
            otp: '',
        },
    });
    const { username, otp, } = useWatch({ control });
    const onCreateOtp = useCallback(async ({ username }: LoginFormData) => {
        await otpCreate({ email: username });
    }, [otpCreate]);
    const onVerifyOtp = useCallback(async () => {
        if (!otp) {
            return;
        }
        const loggedIn = await optVerify({ otp });
        if (loggedIn) {
            setIsLogged(true);
            setJwtOtp(undefined);
        }
    }, [optVerify, otp, setJwtOtp]);
    const onReset = useCallback(() => {
        setJwtOtp(undefined);
    }, [setJwtOtp]);
    const errors = useMemo(() => formState.errors, [formState]);
    const composeOtpErrori18n = useCallback((error: string) => `login:feedback.${error}`, []);
    const isAttemptsExceededError = useMemo(() => error === 'otp.attempts_exceeded', [error]);
    const Countdown = useMemo(() => {
        if (!countdown || loadingVerify)
            return null;
        return (<Text align="center" size={14}>
        {t('login:otp.countdown')}
        {': '}
        <time id="countdown" dateTime={`PT${countdown.split(':')[0]}M${countdown.split(':')[1]}S`}>
          {countdown}
        </time>
      </Text>);
    }, [countdown, loadingVerify, t]);
    const InputMail = useMemo(() => (!isLogged ? (<Stack rowGap={32} hPadding={48} as="form" onSubmit={handleSubmit(onCreateOtp)}>
      <Textfield label={t('common:glossary.email')} autoCapitalize="none" autoCorrect="off" spellCheck={false} type="email" invalid={Boolean(errors.username)} autoComplete="email" hint={errors.username?.message} {...register('username', commonRegisterEmail())}/>
      <Button disabled={loadingCreate} busy={loadingCreate} fullWidth type="submit">
        {t('common:cta.login')}
      </Button>
    </Stack>) : null), [
        commonRegisterEmail,
        errors,
        handleSubmit,
        isLogged,
        loadingCreate,
        onCreateOtp,
        register,
        t,
    ]);
    const InputOtp = useMemo(() => (!isLogged ? (<Stack rowGap={48} as="form" fill={false} hAlign="center">
      <Stack rowGap={16}>
        <Title level="5" align="center">{t('login:otp.title')}</Title>
        <Text align="center" size={isMobile ? 16 : undefined}>
          {t('login:otp.description')}
          {' '}
          <b>{username}</b>
        </Text>
      </Stack>

      <Stack rowGap={16} fill={false} hAlign="center">
        {!isAttemptsExceededError && Countdown}
        {!isAttemptsExceededError
            && ((!loadingVerify) ? (<Otp onComplete={onVerifyOtp} onChange={otp => setValue('otp', otp)} invalid={Boolean(error)}/>) : <Spinner />)}
        {Boolean(error) && (<Text textColor="var(--highlight-red-foreground)" size={14} align="center">
            <Trans i18nKey={composeOtpErrori18n(error as string)} values={{ countdown }} components={[<br />]}/>
          </Text>)}
      </Stack>

      <Button kind="flat" icon="arrow-left" onClick={onReset}>
        {t('common:glossary.back')}
      </Button>
    </Stack>) : null), [isAttemptsExceededError, error, loadingVerify, onReset, onVerifyOtp,
        setValue, t, username, isMobile, Countdown, countdown, composeOtpErrori18n, isLogged]);
    useEffect(() => {
        register('otp');
    }, [register]);
    useEffect(() => {
        const interval = setInterval(() => {
            const endDate = new Date(Number(expirationOtp));
            const now = new Date();
            if (isBefore(endDate, now)) {
                clearInterval(interval);
                setCountdown('');
                setExpirationOtp(undefined);
                setJwtOtp(undefined);
                setErrorVerify(undefined);
                return;
            }
            const totalSeconds = Math.max(0, differenceInSeconds(endDate, now));
            if (!totalSeconds)
                return;
            const minutes = Math.floor(totalSeconds / 60) ?? 0;
            const seconds = totalSeconds % 60;
            const formattedTime = `${minutes}:${String(seconds).padStart(2, '0')}`;
            setCountdown(formattedTime);
        }, 1000);
        return () => clearInterval(interval);
    }, [
        expirationOtp, setExpirationOtp, setJwtOtp, setErrorVerify,
    ]);
    useEffect(() => {
        if (isLogged) {
            const query = getFullQuery().stringQueryNoNext ?? '';
            location.href = query ? `${next}?${query}` : next;
        }
    }, [getFullQuery, isLogged, next, router]);
    if (loadingUser) {
        return null;
    }
    return (<>
      <MetaData title={t('login:meta.title')}/>
      <Stack rowGap={isMobile ? 56 : 88} fill={false} vAlign="center">
        <Stack hAlign="center">
          <Logo width="296"/>
        </Stack>
        {(!jwtOtp) ? InputMail : InputOtp}
      </Stack>
    </>);
};
export default LoginPage;
const getServerSideProps = getPageServerSideProps({ layout: 'gate', isPublic: true });

    async function __Next_Translate__getServerSideProps__195fbb103bb__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__195fbb103bb__ as getServerSideProps }
  