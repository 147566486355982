import { Stack, Text } from '@lualtek/react-components';
import { SlotProps } from 'input-otp';
import { FC } from 'react';

import styles from './otp.module.css';

export type OTPSlotProps = SlotProps

export const OTPSlot: FC<OTPSlotProps> = ({
  char,
  placeholderChar,
  hasFakeCaret,
  isActive,
  ...otherProps
}) => (
  <Stack
    hAlign="center"
    vAlign="center"
    fill={false}
    className={styles.Slot}
    data-slot-active={isActive}
    {...otherProps}
  >
    <Text size={18}>{char ?? placeholderChar}</Text>
    {hasFakeCaret && (
      <Stack vAlign="center" hAlign="center" className={styles.CaretContainer}>
        <div className={styles.Caret} />
      </Stack>
    )}
  </Stack>
);

OTPSlot.displayName = 'OTPSlot';
