import { useTranslate } from '../i18n';

export const useFormHelpers = () => {
  const { t } = useTranslate();
  const commonRegisterName = () => ({
    required: t('common:glossary.field.required'),
    pattern: {
      // Value for HTML pattern attribute
      // value: /^[a-zA-Z0-9À-ÖØ-öø-ÿ\s\-_.\(\)<>"&\/+^$*!?@#%~\|:;]*$/,
      // No emoji, no special chars except some basic ones and accents
      value: /^[a-zA-Z0-9\s()-.+]+$/,
      message: t('common:glossary.field.charsPattern', { chars: '()-.+' }),
    },
  });

  const commonRegisterEmail = () => ({
    required: t('common:glossary.field.required'),
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      message: t('common:glossary.invalidEmail'),
    },
  });

  return {
    commonRegisterName,
    commonRegisterEmail,
  };
};
