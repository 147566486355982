import { useCallback, useMemo, useState } from 'react';
import { useLocalstorageState } from 'rooks';

import { setJWT } from '@/core/auth/jwt';
import { APIHandleError, ApiHandlerErrorInstance } from '@/services/apollo/extract-error';
import { STORAGE_KEY_EXPIRATION_OTP, STORAGE_KEY_JWT_OTP } from '@/services/local-storage/keys';

import { HookProps } from './types';

type OtpProps = {
  email: string;
}

type OptVerifyProps = {
  otp: string;
}

type LoginReturn = {
  jwt?: string;
} | undefined

export const useLoginCall = (props?: HookProps) => {
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [, setErrorCreate] = useState<unknown>();
  const [errorVerify, setErrorVerify] = useState<unknown>();
  const [data, setData] = useState<LoginReturn>();
  const [jwtOtp, setJwtOtp] = useLocalstorageState<string | undefined>(STORAGE_KEY_JWT_OTP);
  const [expirationOtp, setExpirationOtp] = useLocalstorageState<string | undefined>(STORAGE_KEY_EXPIRATION_OTP);

  const errorKeys = useMemo(() => [
    'otp.invalid_otp',
    'otp.attempts_exceeded',
    'otp.expired_otp',
  ], []);

  const otpCreate = useCallback(async (body: OtpProps): Promise<LoginReturn> => {
    setLoadingCreate(true);
    setErrorCreate(undefined);
    try {
      const response = await fetch('/api/auth/otp/create', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok && response.status === 400) {
        const errorJson = await response.json() as unknown;
        throw new ApiHandlerErrorInstance(errorJson as APIHandleError);
      }

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const json = await response.json() as { jwt: string };

      if (!expirationOtp || jwtOtp !== json.jwt) {
        setExpirationOtp((new Date().getTime() + 300000).toString());
      }

      setJwtOtp(json.jwt);

      setLoadingCreate(false);
      return json;
    } catch (error: unknown) {
      setErrorCreate(error);
      setLoadingCreate(false);

      if (error instanceof ApiHandlerErrorInstance) {
        props?.onError?.(error.extensions ?? error.message);
      } else {
        props?.onError?.({
          message: 'Unknown error, please contact us.',
          name: 'UnknownError',
          key: 'unknown_error',
          httpCode: 500,
        });
      }

      return undefined;
    }
  },
  [
    expirationOtp,
    props,
    setExpirationOtp,
    setJwtOtp,
    jwtOtp,
  ]);

  const optVerify = useCallback(async (body: OptVerifyProps): Promise<LoginReturn> => {
    setLoadingVerify(true);
    setErrorVerify(undefined);
    try {
      const response = await fetch('/api/auth/otp/verify', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtOtp}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok && response.status >= 400 && response.status < 500) {
        const errorJson = await response.json() as unknown;
        throw new ApiHandlerErrorInstance(errorJson as APIHandleError);
      }

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const json = await response.json() as { jwt: string };
      setData(json);
      setJWT(json.jwt);
      setLoadingVerify(false);
      return json;
    } catch (error: unknown) {
      setLoadingVerify(false);
      if (error instanceof ApiHandlerErrorInstance && errorKeys.includes(error.extensions.key)) {
        setErrorVerify(error.extensions.key);
      } else {
        props?.onError?.({
          message: 'Unknown error, please contact us.',
          name: 'UnknownError',
          key: 'unknown_error',
          httpCode: 500,
        });
      }

      return undefined;
    }
  },
  [
    jwtOtp,
    errorKeys,
    props,
  ]);

  return {
    otpCreate,
    optVerify,
    loadingCreate,
    loadingVerify,
    setErrorVerify,
    errorVerify,
    data,
    jwtOtp,
    expirationOtp,
    setJwtOtp,
    setExpirationOtp,
  };
};
